<template>
  <div class="card-auctions">
    <div class="grid grid-cols-1r">
      <div class="bg-card-acution bg-agrogo-green-13"></div>
      <div
        class="content-card-auction bg-agrogo-green-13 items-center justify-start"
      >
        <p
          class="font-nexa font-normal text-base md:text-2xl text-white opacity-75 text-left px-4 lg:px-14"
        >
          Espacio <br />
          publicitario
        </p>
      </div>
    </div>
  </div>
</template>

<script></script>

<style></style>
