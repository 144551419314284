<template>
  <AuctionHeader />
  <section class="bg-agrogo-green-3 sizeMinAllView">
    <!-- CARRUSEL Tipos de Subastas -->
    <slider-type-animal />
    <!-- SECCION ENCUENTRA LA SUBASTAS ACTIVAS Y FILTRAR -->
    <div
      class="flex items-center justify-between bg-agrogo-yellow-1 h-8 md:h-14"
    >
      <div class="flex">
        <a
          class="font-nexa font-black text-base text-left pl-7 leading-none md:text-2xl lg:pl-16"
          ><span class="icon-punto text-agrogo-green-6"></span> Subastas
          <span class="font-normal">Activas</span></a
        >
      </div>
      <div class="flex space-x-10">
        <div class="hidden lg:flex">
          <a class="font-nexa text-base text-left leading-none md:text-2xl"
            >Encuentra la Subasta
            <span class="font-black">perfecta para ti</span></a
          >
        </div>
        <div class="flex pr-4 lg:pr-12">
          <button
            @click="$router.push('/filtros-fincas')"
            type="button"
            class="btn-auction-filter"
          >
            Filtrar <span class="icon-filter text-3xs pl-2"></span>
          </button>
        </div>
      </div>
    </div>
    <!-- CARDS AUCTIONS -->
    <div
      class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-content-center place-items-center gap-2 md:gap-6 lg:gap-9 px-2 pt-2 pb-6 md:px-14 lg:px-20 md:pt-2.5 md:pb-5"
    >
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions />
      </router-link>
    </div>
    <!-- SECCION ENCUENTRA LA SUBASTAS ACTIVAS Y FILTRAR -->
    <div
      class="flex items-center justify-between bg-agrogo-yellow-1 h-8 md:h-14"
    >
      <div class="flex items-center">
        <a
          class="font-nexa font-black text-base text-left pl-7 leading-none md:text-2xl lg:pl-16"
          ><span class="icon-punto text-agrogo-red-1"></span> Subastas
          <span class="font-normal">Próximas</span></a
        >
      </div>
    </div>
    <!-- CARDS AUCTIONS -->
    <div
      class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-content-center place-items-center gap-2 md:gap-6 lg:gap-9 px-2 pt-2 pb-6 md:px-14 lg:px-20 md:pt-2.5 md:pb-5"
    >
      <router-link to="/detalle-subastas">
        <card-auctions-inactive />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions-inactive />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions-inactive />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auctions-publicity />
      </router-link>
    </div>
  </section>
</template>

<script>
import AuctionHeader from "@/components/header/HeaderAuction";
import SliderTypeAnimal from "@/components/slider/SliderTypeAnimal";
import CardAuctions from "@/views/auction/cards/CardAuction";
import CardAuctionsInactive from "@/views/auction/cards/CardAuctionInactive";
import CardAuctionsPublicity from "@/views/auction/cards/CardAuctionPublicity";
export default {
  inject: ["provider"],
  name: "Home",
  components: {
    SliderTypeAnimal,
    CardAuctions,
    AuctionHeader,
    CardAuctionsInactive,
    CardAuctionsPublicity,
  },
};
</script>

<style lang="css" scoped></style>
