<template>
  <div class="card-auctions">
    <div class="grid grid-cols-1">
      <div class="bg-card-acution">
        <img class="max-w-none" src="@/assets/images/cardauction.jpg" />
        <div class="publicationcategory bg-agrogo-green-7">
          <p class="font-nexa font-normal text-xs text-white">Super</p>
          <p class="text-agrogo-green-6 size-star leading-none space-x-px">
            <span class="icon-star"></span><span class="icon-star"></span
            ><span class="icon-star"></span><span class="icon-star"></span
            ><span class="icon-star text-agrogo-green-5"></span>
          </p>
        </div>
        <div
          class="flex items-center justify-center w-8 h-7 bg-agrogo-yellow-1 absolute rounded-bl-2xl right-0"
        >
          <a class="icon-heart text-xs text-white"></a>
        </div>
        <a
          class="icon-isologo-agrogo--1 text-2xl text-white absolute right-0 bottom-0 pr-1.5 pb-1 opacity-30 md:text-3xl md:pr-3 md:pb-3"
        ></a>
      </div>
      <div class="content-card-auction">
        <a
          class="font-dinpro font-normal text-xs text-agrogo-yellow-1 text-left mb-1 md:text-sm opacity-85 md:mb-3"
          >ID# <span class="text-agrogo-gray-1">321654987</span></a
        >
        <a
          class="font-nexa font-normal text-white text-base text-left leading-4 md:text-xl md:leading-6"
          >30 Machos Reproductores Bartolo</a
        >
        <a
          class="font-dinpro font-normal text-agrogo-green-4 text-xs text-left md:text-base mb-2 md:font-medium"
          >Medellín,Antioquia</a
        >
        <a
          class="font-dinpro font-normal text-white text-xs text-left md:text-base mb-5 md:mb-10"
          >Puro con Registro Potrero</a
        >
        <a
          class="font-dinpro font-medium text-xs text-agrogo-yellow-1 text-right leading-none md:text-sm"
          >Precio total</a
        >
        <a
          class="font-dinpro font-bold text-2xl text-white text-right md:text-2xl mb-3 md:mb-4"
          ><span
            class="icon-signo-pesos text-agrogo-yellow-1 text-sm pr-1 md:text-lg"
          ></span
          >90.000.000</a
        >
        <div class="flex items justify-end mb-4 md:mb-5">
          <button class="btn-card-auction">Pujar</button>
        </div>
        <p
          class="font-dinpro font-bold text-xs pl-1 text-agrogo-green-6 text-left mb-3 md:text-sm md:mb-2"
        >
          Finaliza <span class="text-white font-normal">en:</span>
        </p>
        <div class="flex items-center justify-center">
          <p class="font-nexa font-black text-white text-lg md:text-3xl">
            <span
              class="icon-punto text-agrogo-green-6 text-sm md:text-base"
            ></span>
            06<span class="font-normal text-xs pr-1.5 md:text-sm">d </span>
            07<span class="font-normal text-xs md:text-sm">m</span> 18<span
              class="font-normal text-xs md:text-sm"
              >s</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style></style>
